<script setup>
import Excellence from './components/excellence.vue';
import Supply from './components/supply.vue';

// isMemberStore: 是否是会员卡白名单商家店铺，说人话就是：是否是优选商家
const { isMemberStore } = JSON.parse(localStorage.getItem('userInfo'));
</script>

<template>
  <Excellence v-if="isMemberStore" />
  <Supply v-else />
</template>
