var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "outer supply-chain" },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-bottom": "30px" },
          on: { "tab-click": _setup.changePage },
          model: {
            value: _setup.pageTab,
            callback: function ($$v) {
              _setup.pageTab = $$v
            },
            expression: "pageTab",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "选品池", name: "no" } }),
          _c("el-tab-pane", { attrs: { label: "已关联商品", name: "yes" } }),
        ],
        1
      ),
      _c(
        "search-panel",
        {
          attrs: { "show-btn": "", flex: "" },
          on: {
            getList: function ($event) {
              return _setup.getList(1)
            },
          },
        },
        [
          _setup.isReset
            ? [
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("商品类目")]),
                    _c(_setup.ChangeCat, {
                      attrs: { "parent-id": 1 },
                      on: { change: _setup.handleChange },
                    }),
                  ],
                  1
                ),
                _c("div", [
                  _c("p", [_vm._v("预估毛利（%）")]),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-input", {
                        class: {
                          input_box:
                            !_setup.params.lowEstimatedProfit &&
                            _setup.params.highEstimatedProfit,
                        },
                        attrs: {
                          size: "mini",
                          placeholder: "请输入最低毛利",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _setup.setFixed(
                              _setup.params,
                              "lowEstimatedProfit",
                              1
                            )
                          },
                        },
                        model: {
                          value: _setup.params.lowEstimatedProfit,
                          callback: function ($$v) {
                            _vm.$set(_setup.params, "lowEstimatedProfit", $$v)
                          },
                          expression: "params.lowEstimatedProfit",
                        },
                      }),
                      _vm._v(" ~ "),
                      _c("el-input", {
                        class: {
                          input_box:
                            _setup.params.lowEstimatedProfit &&
                            !_setup.params.highEstimatedProfit,
                        },
                        attrs: {
                          size: "mini",
                          placeholder: "请输入最高毛利",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _setup.setFixed(
                              _setup.params,
                              "highEstimatedProfit",
                              1
                            )
                          },
                        },
                        model: {
                          value: _setup.params.highEstimatedProfit,
                          callback: function ($$v) {
                            _vm.$set(_setup.params, "highEstimatedProfit", $$v)
                          },
                          expression: "params.highEstimatedProfit",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", [
                  _c("p", [_vm._v("订货价（元）")]),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-input", {
                        class: {
                          input_box:
                            !_setup.params.lowSupplyPrice &&
                            _setup.params.highSupplyPrice,
                        },
                        attrs: {
                          size: "mini",
                          placeholder: "请输入最低价",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _setup.setFixed(
                              _setup.params,
                              "lowSupplyPrice",
                              2
                            )
                          },
                        },
                        model: {
                          value: _setup.params.lowSupplyPrice,
                          callback: function ($$v) {
                            _vm.$set(_setup.params, "lowSupplyPrice", $$v)
                          },
                          expression: "params.lowSupplyPrice",
                        },
                      }),
                      _vm._v(" ~ "),
                      _c("el-input", {
                        class: {
                          input_box:
                            _setup.params.lowSupplyPrice &&
                            !_setup.params.highSupplyPrice,
                        },
                        attrs: {
                          size: "mini",
                          placeholder: "请输入最高价",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _setup.setFixed(
                              _setup.params,
                              "highSupplyPrice",
                              2
                            )
                          },
                        },
                        model: {
                          value: _setup.params.highSupplyPrice,
                          callback: function ($$v) {
                            _vm.$set(_setup.params, "highSupplyPrice", $$v)
                          },
                          expression: "params.highSupplyPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", [
                  _c("p", [_vm._v("店铺销售价（元）")]),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-input", {
                        class: {
                          input_box:
                            !_setup.params.lowSellPrice &&
                            _setup.params.highSellPrice,
                        },
                        attrs: {
                          size: "mini",
                          placeholder: "请输入最低价",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _setup.setFixed(
                              _setup.params,
                              "lowSellPrice",
                              2
                            )
                          },
                        },
                        model: {
                          value: _setup.params.lowSellPrice,
                          callback: function ($$v) {
                            _vm.$set(_setup.params, "lowSellPrice", $$v)
                          },
                          expression: "params.lowSellPrice",
                        },
                      }),
                      _vm._v(" ~ "),
                      _c("el-input", {
                        class: {
                          input_box:
                            _setup.params.lowSellPrice &&
                            !_setup.params.highSellPrice,
                        },
                        attrs: {
                          size: "mini",
                          placeholder: "请输入最高价",
                          clearable: "",
                        },
                        on: {
                          blur: function ($event) {
                            return _setup.setFixed(
                              _setup.params,
                              "highSellPrice",
                              2
                            )
                          },
                        },
                        model: {
                          value: _setup.params.highSellPrice,
                          callback: function ($$v) {
                            _vm.$set(_setup.params, "highSellPrice", $$v)
                          },
                          expression: "params.highSellPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("商品标签（多选）")]),
                    _c(_setup.SearchSelectTag, {
                      ref: "searchSelectTagRef",
                      attrs: { size: "mini" },
                      on: { change: _setup.changeTag },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("铺货状态")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择状态",
                          size: "mini",
                          clearable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _setup.getList(1)
                          },
                        },
                        model: {
                          value: _setup.params.status,
                          callback: function ($$v) {
                            _vm.$set(_setup.params, "status", $$v)
                          },
                          expression: "params.status",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "已铺货", value: 1 },
                        }),
                        _c("el-option", {
                          attrs: { label: "未铺货", value: 0 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("供应商")]),
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        placeholder: "请输入内容",
                        size: "mini",
                      },
                      on: {
                        clear: function ($event) {
                          return _setup.getList(1)
                        },
                      },
                      model: {
                        value: _setup.params.relationSupplierName,
                        callback: function ($$v) {
                          _vm.$set(_setup.params, "relationSupplierName", $$v)
                        },
                        expression: "params.relationSupplierName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("供应商商品ID")]),
                    _c("el-input", {
                      attrs: {
                        size: "mini",
                        placeholder: "请输入内容",
                        clearable: "",
                      },
                      on: {
                        blur: function ($event) {
                          return _setup.setFixed(
                            _setup.params,
                            "relationGoodsIds"
                          )
                        },
                      },
                      model: {
                        value: _setup.params.relationGoodsIds,
                        callback: function ($$v) {
                          _vm.$set(_setup.params, "relationGoodsIds", $$v)
                        },
                        expression: "params.relationGoodsIds",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("商品名称")]),
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        placeholder: "请输入内容",
                        size: "mini",
                      },
                      on: {
                        clear: function ($event) {
                          return _setup.getList(1)
                        },
                      },
                      model: {
                        value: _setup.params.relationGoodsName,
                        callback: function ($$v) {
                          _vm.$set(_setup.params, "relationGoodsName", $$v)
                        },
                        expression: "params.relationGoodsName",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { slot: "button-right", size: "mini" },
              on: {
                click: function ($event) {
                  return _setup.operation("reset")
                },
              },
              slot: "button-right",
            },
            [_vm._v(" 重 置 ")]
          ),
        ],
        2
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _setup.loading,
              expression: "loading",
            },
          ],
          ref: "multipleTable",
          staticClass: "tableBox",
          staticStyle: { width: "100%" },
          attrs: {
            data: _setup.tableData,
            "element-loading-text": "拼命加载中",
            "row-key": "relationGoodsId",
            "header-cell-style": {
              background: "#f5f7fa",
            },
            size: "medium",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: { "margin-left": "148px" },
                        attrs: {
                          data: row.tradeGoodsSkuVoList,
                          size: "medium",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "商品规格", width: "300" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "wd-flex wd-flex-align-center",
                                      },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "50px",
                                            height: "50px",
                                          },
                                          attrs: {
                                            src: scope.row.imageIds,
                                            "preview-src-list": [
                                              scope.row.imageIds,
                                            ],
                                            fit: "cover",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "margin-l5" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(scope.row.specValue) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { label: "店铺销售价（元）", width: "180" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(+scope.row.sellPrice) + " "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { label: "订货价（元）", width: "180" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(+scope.row.supplyPrice) + " "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "estimatedProfit",
                            label: "预估毛利（%)",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          +(
                                            scope.row.estimatedProfit * 100
                                          ).toFixed(2)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "relationGoodsId",
              label: "供应商商品ID",
              width: "110",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "relationGoodsName",
              label: "商品名称",
              "min-width": "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "backendCategoryPath",
              label: "商品类目",
              "min-width": "150",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "tagName", label: "商品标签" },
          }),
          _c("el-table-column", {
            attrs: { prop: "salesSumTrue", label: "店铺销量" },
          }),
          _c("el-table-column", {
            attrs: { label: "预估毛利（%）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.lowEstimatedProfit === row.highEstimatedProfit
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                +(row.lowEstimatedProfit * 100).toFixed(2)
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                +(row.lowEstimatedProfit * 100).toFixed(2)
                              ) +
                              " ~ " +
                              _vm._s(
                                +(row.highEstimatedProfit * 100).toFixed(2)
                              ) +
                              " "
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "订货价（元）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.lowSupplyPrice === row.highSupplyPrice
                      ? _c("span", [
                          _vm._v(" " + _vm._s(+row.lowSupplyPrice) + " "),
                        ])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(+row.lowSupplyPrice) +
                              " ~ " +
                              _vm._s(+row.highSupplyPrice) +
                              " "
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "店铺销售价（元）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.lowSellPrice === row.highSellPrice
                      ? _c("span", [
                          _vm._v(" " + _vm._s(+row.lowSellPrice) + " "),
                        ])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(+row.lowSellPrice) +
                              " ~ " +
                              _vm._s(+row.highSellPrice) +
                              " "
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "供应商" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(" " + _vm._s(row.relationSupplierName) + " "),
                    row.relationSupplierStatusStr
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: row.relationSupplierStatusStr,
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-warning red" })]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "铺货状态", prop: "statusStr" },
          }),
          _c("el-table-column", {
            attrs: { label: "最后一次铺货时间", prop: "lastTimeStr" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _setup.operation("add", row)
                          },
                        },
                      },
                      [_vm._v(" 一键铺品 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _setup.tableData && _setup.tableData.length > 0
        ? _c("el-pagination", {
            attrs: {
              "current-page": _setup.params.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _setup.params.nowPageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _setup.params.count,
            },
            on: {
              "size-change": _setup.handleSizeChange,
              "current-change": _setup.handleCurrentChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }