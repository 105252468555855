<script setup>
import {
  reactive, ref, getCurrentInstance, onMounted, nextTick,
} from 'vue';
import dayjs from 'dayjs';
import ChangeCat from '@/components/common/changeCat.vue';
import SearchSelectTag from '@/components/common/searchSelectTag/index.vue';
import { downloadFile } from '@/utils/download.js';
// import SearchSelectSupplier from '@/components/common/searchSelectSupplier/index.vue';

const app = getCurrentInstance().proxy;
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const search = {
  backendCategoryId: undefined,
  lowEstimatedProfit: undefined,
  highEstimatedProfit: undefined,
  lowSupplyPrice: undefined,
  highSupplyPrice: undefined,
  lowSellPrice: undefined,
  highSellPrice: undefined,
  relationGoodsTagIds: [],
  status: undefined,
  relationSupplierName: '',
  relationGoodsIds: undefined,
  goodsIds: undefined,
  relationGoodsName: undefined,
  currentPage: 1,
  nowPageSize: 10,
  count: 0,
};
const params = reactive({ ...search });

const isReset = ref(true);
const searchSelectTagRef = ref(null);
const loading = ref(false);
const tableData = ref([]);
const pageTab = ref('no');

function handleChange(value) {
  if (value && value.length > 0) {
    params.backendCategoryId = value[value.length - 1];
  } else {
    params.backendCategoryId = '';
  }
}
async function operation(type, row) {
  if (type === 'reset') {
    isReset.value = false;
    for (const key in search) {
      params[key] = search[key];
    }
    console.log(searchSelectTagRef.value);
    getList();
    nextTick(() => {
      isReset.value = true;
    });
  } else if (type === 'delete') {
    let message = '是否确认解除关联?';
    const res = await app.$axios.post(app.$api.supplychain.unRelationTip, {
      id: row.id,
    });
    if (res.code === 0 && res.data && res.data.length) {
      message = `是否确认解除关联，解除后如不出售该商品，请手动将商品ID：${res.data} 从优选店商品列表下架`;
    }
    app.$confirm(message, '提示', {
      confirmButtonText: '确定解除',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      deleteSumit(row);
    }).catch(() => {

    });
  } else if (type === 'add') {
    const path = '/nb/goods/detail';
    const route = app.$router.resolve({
      path,
      query: {
        type: 'edit',
        goodsType: 1,
        backendCategoryId: row.backendCategoryId,
        goodsId: row.relationGoodsId,
        storeId: userInfo.defaultStoreId,
        suppliersId: userInfo.suppliersId,
        suppliedGoodsType: 'add',
        suppliersName: row.relationSupplierName,
        goodsBuyType: row.goodsType,
      },
    });
    window.open(window.location.origin + route.href);
  } else if (type === 'export') {
    // 导出
    await app.$confirm('是否确认导出？');
    const res = await app.$axios.get(app.$api.deliver_center.download_template, { params: getParams(1, 'get') }, { responseType: 'blob' });
    const time = dayjs(new Date()).format('YYYY-MM-DD_hh.mm.ss');
    downloadFile(res, `优选供货商品导出_${time}.xls`);
  }
}
async function deleteSumit(row) {
  const res = await app.$axios.post(app.$api.supplychain.unRelation, {
    id: row.id,
    relationSupplierId: row.relationSupplierId,
  });
  if (res.code === 0) {
    app.$message.success('操作成功！');
    getList();
  }
}
// 分页控制
function handleSizeChange(val) {
  params.nowPageSize = val;
  params.currentPage = 1;
  getList();
}
function handleCurrentChange(val) {
  params.currentPage = val;
  getList();
}
function getParams(page, type) {
  return {
    ...params,
    lowEstimatedProfit: params.lowEstimatedProfit && (params.lowEstimatedProfit / 100).toFixed(3),
    highEstimatedProfit: params.highEstimatedProfit && (params.highEstimatedProfit / 100).toFixed(3),
    relationGoodsIds: params.relationGoodsIds
      ? (type === 'post' ? [params.relationGoodsIds] : params.relationGoodsIds)
      : [],
    goodsIds: params.goodsIds
      ? (type === 'post' ? [params.goodsIds] : params.goodsIds)
      : [],
    currentPage: page || params.currentPage,
    pageSize: params.nowPageSize,
    nowPageSize: undefined,
    count: undefined,
  };
}
async function getList(num) {
  loading.value = true;
  const res = await app.$axios.post(app.$api.supplychain.page, getParams(num, 'post'));
  loading.value = false;
  if (res.code === 0) {
    tableData.value = res.data.records || [];
    params.count = Number(res.data.total);

    tableData.value.forEach(row => {
      row.lastTimeStr = row.lastRelationTime ? dayjs(row.lastRelationTime).format('YYYY-MM-DD HH:mm:ss') : '--';
    })
  }
}
function changeTag(item) {
  params.relationGoodsTagIds = item;
  getList(1);
}
function changeSupplier(e) {
  params.relationSupplierName = e.suppliersName;
  getList(1);
}
function setFixed(params, key, num = 0) {
  params[key] = parseFloat(params[key]) ? parseFloat(params[key]).toFixed(num) : '';
}
onMounted(() => {
  getList(1);
});

function changePage(tab) {
  if (tab.name === 'yes') app.$router.push('/supplyChain/goods/alreadyAssociated');
}
</script>

<template>
  <div class="outer supply-chain">
    <el-tabs v-model="pageTab" @tab-click="changePage" style="margin-bottom: 30px;">
      <el-tab-pane label="选品池" name="no"></el-tab-pane>
      <el-tab-pane label="已关联商品" name="yes"></el-tab-pane>
    </el-tabs>
    <search-panel
      show-btn
      flex
      @getList="getList(1)"
    >
      <template v-if="isReset">
        <div>
          <p>商品类目</p>
          <ChangeCat
            :parent-id="1"
            @change="handleChange"
          />
        </div>
        <div>
          <p>预估毛利（%）</p>
          <div class="flex">
            <el-input
              v-model="params.lowEstimatedProfit"
              :class="{
                'input_box': !params.lowEstimatedProfit && params.highEstimatedProfit
              }"
              size="mini"
              placeholder="请输入最低毛利"
              clearable
              @blur="setFixed(params, 'lowEstimatedProfit', 1)"
            />
            ~
            <el-input
              v-model="params.highEstimatedProfit"
              :class="{
                'input_box': params.lowEstimatedProfit && !params.highEstimatedProfit
              }"
              size="mini"
              placeholder="请输入最高毛利"
              clearable
              @blur="setFixed(params, 'highEstimatedProfit', 1)"
            />
          </div>
        </div>
        <div>
          <p>订货价（元）</p>
          <div class="flex">
            <el-input
              v-model="params.lowSupplyPrice"
              :class="{
                'input_box': !params.lowSupplyPrice && params.highSupplyPrice
              }"
              size="mini"
              placeholder="请输入最低价"
              clearable
              @blur="setFixed(params, 'lowSupplyPrice', 2)"
            />
            ~
            <el-input
              v-model="params.highSupplyPrice"
              :class="{
                'input_box': params.lowSupplyPrice && !params.highSupplyPrice
              }"
              size="mini"
              placeholder="请输入最高价"
              clearable
              @blur="setFixed(params, 'highSupplyPrice', 2)"
            />
          </div>
        </div>
        <div>
          <p>店铺销售价（元）</p>
          <div class="flex">
            <el-input
              v-model="params.lowSellPrice"
              :class="{
                'input_box': !params.lowSellPrice && params.highSellPrice
              }"
              size="mini"
              placeholder="请输入最低价"
              clearable
              @blur="setFixed(params, 'lowSellPrice', 2)"
            />
            ~
            <el-input
              v-model="params.highSellPrice"
              :class="{
                'input_box': params.lowSellPrice && !params.highSellPrice
              }"
              size="mini"
              placeholder="请输入最高价"
              clearable
              @blur="setFixed(params, 'highSellPrice', 2)"
            />
          </div>
        </div>
        <div>
          <p>商品标签（多选）</p>
          <SearchSelectTag
            ref="searchSelectTagRef"
            size="mini"
            @change="changeTag"
          />
        </div>
        <!-- <div>
          <p>关联商品状态</p>
          <el-select
            v-model="params.status"
            placeholder="请选择状态"
            size="mini"
            clearable
            @change="getList(1)"
          >
            <el-option
              label="已关联"
              :value="1"
            />
            <el-option
              label="未关联"
              :value="0"
            />
          </el-select>
        </div> -->
        <div>
          <p>铺货状态</p>
          <el-select
            v-model="params.status"
            placeholder="请选择状态"
            size="mini"
            clearable
            @change="getList(1)"
          >
            <el-option
              label="已铺货"
              :value="1"
            />
            <el-option
              label="未铺货"
              :value="0"
            />
          </el-select>
        </div>
        <div>
          <p>供应商</p>
          <!-- <SearchSelectSupplier @change="changeSupplier" /> -->
          <el-input
            v-model="params.relationSupplierName"
            clearable
            placeholder="请输入内容"
            size="mini"
            @clear="getList(1)"
          />
        </div>
        <div>
          <p>供应商商品ID</p>
          <el-input
            v-model="params.relationGoodsIds"
            size="mini"
            placeholder="请输入内容"
            clearable
            @blur="setFixed(params, 'relationGoodsIds')"
          />
        </div>
        <div>
          <p>商品名称</p>
          <el-input
            v-model="params.relationGoodsName"
            clearable
            placeholder="请输入内容"
            size="mini"
            @clear="getList(1)"
          />
        </div>
        <!-- <div>
          <p>关联优选商品ID</p>
          <el-input
            v-model="params.goodsIds"
            size="mini"
            placeholder="请输入内容"
            clearable
            @blur="setFixed(params, 'goodsIds')"
          />
        </div> -->
      </template>

      <el-button
        slot="button-right"
        size="mini"
        @click="operation('reset')"
      >
        重 置
      </el-button>
      <!-- <el-button
        slot="button-right"
        size="mini"
        @click="operation('export')"
      >
        导 出
      </el-button> -->
    </search-panel>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData"
      class="tableBox"
      element-loading-text="拼命加载中"
      style="width: 100%"
      row-key="relationGoodsId"
      :header-cell-style="{
        background: '#f5f7fa',
      }"
      size="medium"
    >
      <el-table-column type="expand">
        <template slot-scope="{row}">
          <el-table
            :data="row.tradeGoodsSkuVoList"
            style="margin-left: 148px"
            size="medium"
          >
            <el-table-column
              label="商品规格"
              width="300"
            >
              <template slot-scope="scope">
                <div class="wd-flex wd-flex-align-center">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="scope.row.imageIds"
                    :preview-src-list="[scope.row.imageIds]"
                    fit="cover"
                  />
                  <div class="margin-l5">
                    {{ scope.row.specValue }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="店铺销售价（元）"
              width="180"
            >
              <template slot-scope="scope">
                {{ +scope.row.sellPrice }}
              </template>
            </el-table-column>
            <el-table-column
              label="订货价（元）"
              width="180"
            >
              <template slot-scope="scope">
                {{ +scope.row.supplyPrice }}
              </template>
            </el-table-column>
            <el-table-column
              prop="estimatedProfit"
              label="预估毛利（%)"
            >
              <template slot-scope="scope">
                {{ +(scope.row.estimatedProfit * 100).toFixed(2) }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        prop="relationGoodsId"
        label="供应商商品ID"
        width="110"
      />
      <el-table-column
        prop="relationGoodsName"
        label="商品名称"
        min-width="100"
      />
      <el-table-column
        prop="backendCategoryPath"
        label="商品类目"
        min-width="150"
      />
      <el-table-column
        prop="tagName"
        label="商品标签"
      />
      <el-table-column
        prop="salesSumTrue"
        label="店铺销量"
      />
      <el-table-column
        label="预估毛利（%）"
      >
        <template slot-scope="{row}">
          <span v-if="row.lowEstimatedProfit === row.highEstimatedProfit">
            {{ +(row.lowEstimatedProfit * 100).toFixed(2) }}
          </span>
          <span v-else>
            {{ +(row.lowEstimatedProfit * 100).toFixed(2) }}
            ~
            {{ +(row.highEstimatedProfit * 100).toFixed(2) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="订货价（元）"
      >
        <template slot-scope="{row}">
          <span v-if="row.lowSupplyPrice === row.highSupplyPrice">
            {{ +row.lowSupplyPrice }}
          </span>
          <span v-else>
            {{ +row.lowSupplyPrice }} ~ {{ +row.highSupplyPrice }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="店铺销售价（元）"
      >
        <template slot-scope="{row}">
          <span v-if="row.lowSellPrice === row.highSellPrice">
            {{ +row.lowSellPrice }}
          </span>
          <span v-else>
            {{ +row.lowSellPrice }} ~ {{ +row.highSellPrice }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="供应商"
      >
        <template slot-scope="{row}">
          {{ row.relationSupplierName }}
          <el-tooltip
            v-if="row.relationSupplierStatusStr"
            class="item"
            effect="dark"
            :content="row.relationSupplierStatusStr"
            placement="top"
          >
            <i
              class="el-icon-warning red"
            ></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="铺货状态" prop="statusStr"></el-table-column>
      <el-table-column label="最后一次铺货时间" prop="lastTimeStr"></el-table-column>
      <el-table-column
        label="操作"
      >
        <template
          slot-scope="{row}"
        >
          <!-- <el-link
            v-if="+row.status === 0"
            type="primary"
            @click="operation('add', row)"
          >
            一键铺品
          </el-link>
          <el-link
            v-else
            type="danger"
            @click="operation('delete', row)"
          >
            解除关联
          </el-link> -->
          <el-link
            type="primary"
            @click="operation('add', row)"
          >
            一键铺品
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData && tableData.length > 0"
      :current-page="params.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="params.nowPageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="params.count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<style scoped lang="scss">
.tableBox{
  :deep(.el-table__header){
    .el-table__cell{
      font-weight: bold;
      color: #333;
    }
  }
  :deep(.el-table__row){
    font-size: 12px;
  }
}
:deep(.el-input-number .el-input__inner) {
  text-align: left;
}
.input_box :deep(input) {
  border-color: red;
}
</style>
