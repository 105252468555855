<script setup>
import {
  reactive, ref, getCurrentInstance, onMounted,
} from 'vue';
import ChangeCat from '@/components/common/changeCat.vue';
import AddGoodsDialog from './AddGoodsDialog.vue';

const app = getCurrentInstance().proxy;

const search = {
  backendCategoryId: undefined,
  relationGoodsName: undefined,
  relationGoodsIds: undefined,
  status: undefined,
  currentPage: 1,
  nowPageSize: 10,
  count: 0,
};
const params = reactive({ ...search });

const loading = ref(false);
const tableData = ref([]);
const showAddDialog = ref(false);

const tableData2 = [{ img: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/default/1658302624961.png', name: '500g', price: 100 },
  { img: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/default/1659403902508.jpeg', name: '500g', price: 100 }];

function handleChange(value) {
  if (value && value.length > 0) {
    params.backendCategoryId = value[value.length - 1];
  } else {
    params.backendCategoryId = '';
  }
}
function operation(type, row) {
  if (type === 'reset') {
    for (const key in search) {
      params[key] = search[key];
    }
    getList(1);
  } else if (type === 'delete') {
    app.$confirm('是否确认移除选品中心?', '提示', {
      confirmButtonText: '确定删除',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      deleteSumit(row);
    }).catch(() => {

    });
  } else {
    const path = '/supplyChain/cooperation/detail';
    app.$router.push({
      path,
      query: {
        type,
        id: row?.id,
      },
    });
  }
}
async function deleteSumit(row) {
  const res = await app.$axios.get(app.$api.supplychain.remove, {
    params: {
      id: row.id,
    },
  });
  if (res.code === 0) {
    app.$message.success('操作成功！');
    getList();
  }
}
// 分页控制
function handleSizeChange(val) {
  params.nowPageSize = val;
  params.currentPage = 1;
  getList();
}
function handleCurrentChange(val) {
  params.currentPage = val;
  getList();
}
async function getList(num) {
  if (num) params.currentPage = num;
  loading.value = true;
  const res = await app.$axios.post(app.$api.supplychain.page, {
    ...params,
    relationGoodsIds: params.relationGoodsIds ? [params.relationGoodsIds] : [],
    currentPage: params.currentPage,
    pageSize: params.nowPageSize,
    nowPageSize: undefined,
    count: undefined,
  });
  loading.value = false;
  if (res.code === 0) {
    tableData.value = res.data.records;
    params.count = Number(res.data.total);
  }
}
function onShowAddDialog() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (!userInfo.isCooperation) {
    app.$message.error('请先申请供应链合作');
    return;
  }
  showAddDialog.value = true;
}
onMounted(() => {
  getList(1);
});
</script>

<template>
  <div class="outer supply-chain">
    <search-panel
      show-btn
      flex
      @getList="getList(1)"
    >
      <div>
        <p>商品类目</p>
        <ChangeCat
          :parent-id="1"
          @change="handleChange"
        />
      </div>
      <!-- 优选商品优化需求中删掉该选项
      <div>
        <p>供货状态</p>
        <el-select
          v-model="params.status"
          placeholder="请选择状态"
          size="mini"
          clearable
          @change="getList(1)"
        >
          <el-option
            label="供货中"
            :value="1"
          />
          <el-option
            label="未供货"
            :value="0"
          />
        </el-select>
      </div> -->
      <div>
        <p>商品ID</p>
        <el-input-number
          v-model="params.relationGoodsIds"
          size="mini"
          :controls="false"
          :min="0"
          :precision="0"
          placeholder="请输入内容"
          clearable
        />
      </div>
      <div>
        <p>商品名称</p>
        <el-input
          v-model="params.relationGoodsName"
          clearable
          placeholder="请输入内容"
          size="mini"
          @clear="getList(1)"
        />
      </div>

      <el-button
        slot="button-right"
        size="mini"
        @click="operation('reset')"
      >
        重 置
      </el-button>
      <el-button
        slot="button-right"
        type="primary"
        size="mini"
        @click="onShowAddDialog"
      >
        添加供货商品
      </el-button>
    </search-panel>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData"
      class="tableBox"
      element-loading-text="拼命加载中"
      style="width: 100%"
      row-key="relationGoodsId"
      :header-cell-style="{
        background: '#f5f7fa',
      }"
      size="medium"
    >
      <el-table-column type="expand">
        <template slot-scope="{row}">
          <el-table
            :data="row.tradeGoodsSkuVoList"
            style="margin-left: 148px"
            size="medium"
          >
            <el-table-column
              label="商品规格"
            >
              <template slot-scope="scope">
                <div class="wd-flex wd-flex-align-center">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="scope.row.imageIds"
                    :preview-src-list="[scope.row.imageIds]"
                    fit="cover"
                  />
                  <div class="margin-l5">
                    {{ scope.row.specValue }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="店铺销售价"
            >
              <template slot-scope="scope">
                {{ +scope.row.sellPrice }}
              </template>
            </el-table-column>
            <el-table-column
              label="供货价"
            >
              <template slot-scope="scope">
                {{ +scope.row.supplyPrice }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        prop="relationGoodsId"
        label="商品ID"
        width="100"
      />
      <el-table-column
        prop="relationGoodsName"
        label="商品名称"
      />
      <el-table-column
        prop="backendCategoryPath"
        label="商品类目"
      />
      <!-- 优选商品优化需求中删掉该选项
       <el-table-column
        prop="statusStr"
        label="供货状态"
      /> -->
      <el-table-column
        label="操作"
        width="150"
      >
        <template
          slot-scope="{row}"
        >
          <el-link
            type="danger"
            @click="operation('delete', row)"
          >
            移除选品中心
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData && tableData.length > 0"
      :current-page="params.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="params.nowPageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="params.count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <AddGoodsDialog
      :show="showAddDialog"
      @clear="showAddDialog = false"
      @success="getList(1);"
    />
  </div>
</template>

<style scoped lang="scss">
.tableBox{
  :deep(.el-table__header){
    .el-table__cell{
      font-weight: bold;
      color: #333;
    }
  }
  :deep(.el-table__row){
    font-size: 12px;
  }
}
:deep(.el-input-number .el-input__inner) {
  text-align: left;
}
</style>
